import React, { Fragment } from "react";
import { Route, Routes } from "react-router-dom";

import About from "./components/About";
import BackgroundCheck from "./components/BackgroundCheck";
import BiometricVerification from "./components/BiometricVerification";
import Careers from "./components/Careers";
import CognitiveVideo from "./components/CognitiveVideo";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Home from "./components/Home";
import Integration from "./components/Integration";
import LocationIdentification from "./components/LocationIdentification";
import Security from "./components/Security";

function App() {
  return (
    <>
      <Header />
      <Routes>
        <Route
          path="/biometric-verification"
          element={<BiometricVerification />}
        />
        <Route
          path="/location-identification"
          element={<LocationIdentification />}
        />
        <Route path="/background-check" element={<BackgroundCheck />} />
        <Route path="/cognitive-video" element={<CognitiveVideo />} />
        <Route path="/security" element={<Security />} />
        <Route path="/integration" element={<Integration />} />
        <Route path="/about" element={<About />} />
        <Route path="/careers" element={<Careers />} />
        <Route path="/" element={<Home />} />
      </Routes>
      <Footer />
      {/* // <!-- Mobile smart banner ("Get the app") --> */}
      <div className="smart-banner-wrapper" id="smart-banner">
        <a className="smart-banner" href="/">
          <div className="flex">
            <div className="smart-banner-text">
              <p className="smart-banner-text-header">ValidEntry</p>
              <p className="disclosures">Bank. Save. Invest.</p>
            </div>
          </div>
          <div className="smart-banner-button">
            <div className="button">Coming Soon</div>
          </div>
        </a>
      </div>
    </>
  );
}

export default App;
