import React from "react";

import GeneralContactForm from "../Form/GeneralContactForm";

import "./styles.scss";

function LocationIdentification() {
  return (
    <div className="biometric-verification">
      <div className="banner">
        <div className="banner-inner">
          <h2>Location</h2>
          <h1>
            Geo mapping,
            <br /> precise accuracy
          </h1>
          <input type="button" value="GET STARTED" />
          {/* <p>Needle sharp digital mapping</p> */}
        </div>
      </div>
      {/* <div className="features">
        <h1>Imagine the capabilities</h1>
        <p>
          Seamlessly verify, identify and locate consumers regardless of the country
          they reside in. Digital mapping provides pinpointed location results that
          add an extra layer of security and fraud mitigation. Automate and streamline
          compliance while building a compliance solution to protect your assets across
          all geographical locations.
                  
                </p>
        <div className="feature-list">
          <div className="feature">
            <img
              src="https://www.circle.com/hubfs/product%20brand/inset-gummie-usdc.png"
              alt="Icon"
            />
            <p>Built on USD Coin (USDC)</p>
          </div>
          <div className="feature">
            <img
              src="https://www.circle.com/hubfs/2tone/email-assets/calendar-bazooka.png"
              alt="Icon"
            />
            <p>Fixed rates and flexible terms</p>
          </div>
          <div className="feature">
            <img src="https://www.circle.com/hubfs/btc.png" alt="Icon" />
            <p>Overcollateralized with bitcoin</p>
          </div>
          <div className="feature">
            <img
              src="https://www.circle.com/hubfs/2tone/business-apple.png"
              alt="Icon"
            />
            <p>Institutional-grade</p>
          </div>
          <div className="feature">
            <img
              src="https://www.circle.com/hubfs/2tone/bank-gumdrop.svg"
              alt="Icon"
            />
            <p>Clear regulatory framework</p>
          </div>
          <div className="feature">
            <img
              src="https://www.circle.com/hubfs/convert-jelly.png"
              alt="Icon"
            />
            <p>Opt to automatically renew investments at maturity****</p>
          </div>
        </div>
      </div> */}
      <div className="content">
        <div className="content-text">
          {/* <span>BUILT ON USDC</span> */}
          <h2> Pointed digital mapping</h2>
        </div>
        <p>
          Seamlessly verify, identify and locate consumers regardless of the
          country they reside in. Digital mapping provides pinpointed location
          results that add an extra layer of security and fraud mitigation.
          Automate and streamline compliance while building a compliance
          solution to protect your assets across all geographical locations.
        </p>
        <div className="content-image">
          <img src="https://www.circle.com/hubfs/Compressed%20Images/USDC%20-%20compressed.png" />
        </div>
      </div>
      <section className="content content-list">
        <div>
          {" "}
          <h3>Authentic. Accurate. Precise.</h3>
          <h5>
            Practical AI, measurable outcomes, highly intelligent results.
          </h5>
        </div>

        <div className="list">
          <div className="list-item">
            <div className="splash-icon-container large list-image">
              <img
                className="splash-icon bottom"
                src="https://cdn.albert.com/static/img/splash/splash-2021-08/icons/ic_cashbonuses.svg?v=d76a0adf1ba0d13c15d439fe066debfa67aafb8c"
                alt=""
              />
            </div>
            <b>Validate Attributes</b>
            <p>
              Dynamic, digital consumer data enables regulated entities to
              capture essential authentication information.
            </p>
          </div>
          <div className="list-item">
            <div className="splash-icon-container large list-image">
              <img
                className="splash-icon bottom"
                src="https://cdn.albert.com/static/img/splash/splash-2021-08/icons/ic_cashbonuses.svg?v=d76a0adf1ba0d13c15d439fe066debfa67aafb8c"
                alt=""
              />
            </div>
            <b>Risk Profiling</b>
            <p>
              Utilize authenticated data points to determine the customers
              business and risk profile.
            </p>
          </div>
          <div className="list-item">
            <div className="splash-icon-container large list-image">
              <img
                className="splash-icon bottom"
                src="https://cdn.albert.com/static/img/splash/splash-2021-08/icons/ic_unlimitedtransfers.svg?v=d76a0adf1ba0d13c15d439fe066debfa67aafb8c"
                alt=""
              />
            </div>
            <b>Authorize Access</b>
            <p>
              Authenticate risk profiles to allow entry into accounts and
              applications with ongoing monitoring.
            </p>
          </div>
          <div className="list-item">
            <div className="splash-icon-container large list-image">
              <img
                className="splash-icon bottom"
                src="https://cdn.albert.com/static/img/splash/splash-2021-08/icons/ic_unlimitedtransfers.svg?v=d76a0adf1ba0d13c15d439fe066debfa67aafb8c"
                alt=""
              />
            </div>
            <b>Multi-Source Data</b>
            <p>
              Aggregated data points increase authenticity with pinpointed true
              location using IP, GSM, WiFi and GPS.
            </p>
          </div>
          <div className="list-item">
            <div className="splash-icon-container large list-image">
              <img
                className="splash-icon bottom"
                src="https://cdn.albert.com/static/img/splash/splash-2021-08/icons/ic_unlimitedtransfers.svg?v=d76a0adf1ba0d13c15d439fe066debfa67aafb8c"
                alt=""
              />
            </div>
            <b>Tighten Compliance</b>
            <p>
              Increasing regulatory risk and demands KYC/AML requirements are
              comprehensive, geolocation strengthens authentication.
            </p>
          </div>
          <div className="list-item">
            <div className="splash-icon-container large list-image">
              <img
                className="splash-icon bottom"
                src="https://cdn.albert.com/static/img/splash/splash-2021-08/icons/ic_unlimitedtransfers.svg?v=d76a0adf1ba0d13c15d439fe066debfa67aafb8c"
                alt=""
              />
            </div>
            <b>Address Verification</b>
            <p>Reference data for 240+ countries</p>
          </div>
        </div>
      </section>
      <div className="content content-reverse">
        <div className="content-image">
          <img src="/images/Locaton.png" />
        </div>
        <div className="content-text">
          <h2 style={{ textAlign: "center" }}>Global Location Accuracy</h2>
        </div>
        <p>
          Easily pinpoint and identify consumers regardless of geo location.
          Precision mapping and IP validation validate discrepancies instantly.
        </p>
      </div>
      <div className="trust">
        <h2>Anti-fraud, intelligent automation</h2>
        <div className="trust-list">
          <div className="trust-item">
            <div className="flex-grow-1">
              <div>
                <b>GLOBAL COMPLIANCE</b>
                <p>
                  Easily verify & identify consumers regardless of the country
                  they reside in
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                className="justify-lg-start"
              >
                <img src="https://www.circle.com/hubfs/Compressed%20Images/USDC%20-%20compressed.png" />
              </div>
            </div>
            <div>
              {" "}
              <img src="/images/logo.png" />
            </div>

            <b>TRUST & TRANSPARENCY</b>
            <br />
            <span>Leverage our global compliance solutions</span>
          </div>
          <div className="trust-item">
            <div className="flex-grow-1">
              <div>
                <b>FIGHT FRAUD</b>
                <p>
                  Identify synthetic impersonation, target discrepancies and
                  pinpoint truth in verification.
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                className="justify-lg-start"
              >
                <img src="https://www.circle.com/hubfs/Compressed%20Images/USDC%20-%20compressed.png" />
              </div>
            </div>
            <div>
              {" "}
              <img src="/images/logo.png" />
            </div>

            <b>DATA & PROTECTION</b>
            <br />
            <span>Powerful fraud detection tools protect your business</span>
          </div>

          <div className="trust-item">
            <div className="flex-grow-1">
              <div>
                <b>Prevent loss</b>
                <p>Stop organized fraud rings by going beyond standard KYC</p>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                className="justify-lg-start"
              >
                <img src="https://www.circle.com/hubfs/Compressed%20Images/USDC%20-%20compressed.png" />
              </div>
            </div>
            <div>
              {" "}
              <img src="/images/logo.png" />
            </div>

            <b>TRENDS & VALIDATION</b>
            <br />
            <span>Deploy layers of security against bad actors</span>
          </div>
        </div>
        <a href="/#">READ MORE</a>
      </div>
      <div className="content content-bottom">
        <div className="content-text">
          <h2>Explainable Data Mitgates Risk</h2>
          <p>
            Intelligent compliance solutions cover AML (anti-money laundering),
            PEP, Global Sanctions, OSFI, AUSTRAC, DFAT and more. Enable a suite
            of tools that fight fraud 24 hours a day, 7 days a week.
          </p>
          <a href="/#">Find out how</a>
        </div>
        <div className="content-image">
          <img src="https://www.circle.com/hubfs/Compressed%20Images/USDC%20-%20compressed.png" />
        </div>
      </div>
      <div className="rates">
        <h2>Circle Yield current rates</h2>
        <p>
          Circle Yield, an{" "}
          <a href="/#">institutional investment built on USDC</a>, offers short
          fixed-income terms ranging from from one to twelve months, with
          current interest rates of:
        </p>
        <div className="rate-list">
          <div className="rate">
            <h4>FIXED SHORT TERM**</h4>
            <div className="rate-nested-list">
              <div className="rate-nested">
                <h1>
                  0<sup>%</sup>
                </h1>
                <h3>1 month fixed rate</h3>
              </div>
              <div className="rate-nested">
                <h1>
                  0<sup>%</sup>
                </h1>
                <h3>3 month fixed rate</h3>
              </div>
            </div>
          </div>
          <div className="rate">
            <h4>FIXED LONG TERM**</h4>
            <div className="rate-nested-list">
              <div className="rate-nested">
                <h1>
                  0<sup>%</sup>
                </h1>
                <h3>6 month fixed rate</h3>
              </div>
              <div className="rate-nested">
                <h1>
                  0<sup>%</sup>
                </h1>
                <h3>12 month fixed rate</h3>
              </div>
            </div>
          </div>
        </div>
        <span>
          Rates are purely indicative and are subject to change pending
          availability, approval and market conditions.
        </span>
      </div>
      <div className="signup">
        <h1>
          Start protecting <span>your business</span>
        </h1>
        <p>
          Access to fast, effortless scaling that moves with your business
          without risking quality. Know you’re in compliance without the
          customer drop-off.
        </p>
        <div className="signup-actions">
          <a href="/#">GET STARTED</a>
          <a href="/#">CONTACT SALES</a>
        </div>
      </div>
      <GeneralContactForm />
    </div>
  );
}

export default LocationIdentification;
