/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-unknown-property */
import React, { useEffect, useState } from "react";

function Home() {
  const [modal, setModal] = useState("");

  useEffect(() => {
    if (modal === "flex") {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
  }, [modal]);

  //   const openModal = () => setModal('flex');
  const closeModal = () => setModal("none");

  return (
    <div id="notice-wrap" className="wrap" style={{ overflowX: "hidden" }}>
      <div className="heroCont">
        <iframe
          title="bg-eye"
          aria-label="Background Eye Video"
          data-src="//play.vidyard.com/fx6TXJEsCCKYMLDyThGAah/type/background?preview=1"
          scrolling="no"
          frameBorder="0"
          allowtransparency="false"
          allowFullScreen
          src="//play.vidyard.com/fx6TXJEsCCKYMLDyThGAah/type/background?preview=1"
          className="vyLoad"
        />
        <div className="heroChild">
          <div className="text-mainHero">
            <h1>
              Trust-first
              <br />
              Digital
              <br />
              Validation
            </h1>
            <div className="heroDes">
              Real-time identity verification, onboarding & access management.
            </div>
            <a
              href="https://verify.validentry.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Get started
            </a>
          </div>
          <img
            src="/images/VE Landing iphone image_iphone13prographite_portrait.png"
            className="heroImageMainText"
            alt=""
          />
        </div>
      </div>
      <div className="secondPartCont">
        <div>
          Innovative digital identity solutions that empower your products and
          services. ValidEntry helps you prevent identity theft, streamline
          compliance processes, and improve the customer experience.
        </div>
      </div>
      <div className="main-container main-containerBanking">
        {/* <!-- Section: Banking --> */}
        <section className="main-section">
          <div className="round-card reveal-on">
            <div className="round-card-inner reveal-delay round-card-innerBanking">
              <div className="round-card-content">
                <div className="bankingSmallText">KYC FOR YOUR BUSINESS</div>
                <div className="bankingHeading bankingHeading1">
                  Solutions for everyone, anywhere,{" "}
                  <span className="gradient">on any device</span>
                </div>
                <div className="bankingDesc">
                  With AI-driven automation, intelligent customer insights, and
                  compliance standards built to scale, streamline the know your
                  customer process, digital identification is made simple.
                  Intuitive,
                </div>
                <div className="bankingFlex">
                  <a href="/#">
                    Explore KYC{" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-arrow-right"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fillRule="evenodd"
                        d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                      />
                    </svg>
                  </a>
                </div>
              </div>
              <div className="round-card-img-container cash">
                <picture className="center-margin">
                  <source
                    srcSet="/images/image_adobe_express (3).png"
                    type="image/webp"
                  />
                  <img
                    className="splash-app-feature img-scale"
                    src="/images/image_adobe_express (3).png"
                    alt=""
                  />
                </picture>
              </div>
            </div>
            <div className="circle-plus reveal-standard">
              <svg
                width="33"
                height="33"
                viewBox="0 0 33 33"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M31.1096 16.3242C31.1096 24.5407 24.4489 31.2014 16.2324 31.2014C8.01597 31.2014 1.35523 24.5407 1.35523 16.3242C1.35523 8.10777 8.01597 1.44703 16.2324 1.44703C24.4489 1.44703 31.1096 8.10777 31.1096 16.3242Z"
                  stroke="#333333"
                  strokeWidth="2.24561"
                />
                <line
                  x1="16.5017"
                  y1="9.27124"
                  x2="16.5017"
                  y2="24.0923"
                  stroke="#333333"
                  strokeWidth="2.24561"
                  strokeLinecap="round"
                />
                <line
                  x1="9.17749"
                  y1="16.6233"
                  x2="23.9985"
                  y2="16.6233"
                  stroke="#333333"
                  strokeWidth="2.24561"
                  strokeLinecap="round"
                />
              </svg>
            </div>
          </div>
        </section>

        <section className="main-section">
          <div className="round-card reveal-on">
            <div className="round-card-inner reveal-delay round-card-innerBanking">
              <div className="round-card-content">
                <div className="bankingSmallText">TRUST-FIRST SECURITY</div>
                <div className="bankingHeading bankingHeading2">
                  Transcending <span className="gradient">the ordinary</span>
                </div>
                <div className="bankingDesc">
                  Verify identities with confidence and maintain a positive
                  customer experience without sacrificing security. Our solution
                  is designed to meet the highest security standards, providing
                  enterprise-grade data protection and ensuring the integrity of
                  digital transactions. Protect your brand and authentically
                  engage with your customers.{" "}
                </div>
                <div className="bankingFlex">
                  <a href="/#">
                    Learn about trust-first security{" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-arrow-right"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fillRule="evenodd"
                        d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                      />
                    </svg>
                  </a>
                </div>
              </div>
              <div className="round-card-img-container cash">
                <picture className="center-margin">
                  <source
                    srcSet="/images/image_adobe_express (2).png"
                    type="image/webp"
                  />
                  <img
                    className="splash-app-feature img-scale"
                    src="/images/image_adobe_express (2).png"
                    alt=""
                  />
                </picture>
              </div>
            </div>
            <div className="circle-plus reveal-standard">
              <svg
                width="33"
                height="33"
                viewBox="0 0 33 33"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M31.1096 16.3242C31.1096 24.5407 24.4489 31.2014 16.2324 31.2014C8.01597 31.2014 1.35523 24.5407 1.35523 16.3242C1.35523 8.10777 8.01597 1.44703 16.2324 1.44703C24.4489 1.44703 31.1096 8.10777 31.1096 16.3242Z"
                  stroke="#333333"
                  strokeWidth="2.24561"
                />
                <line
                  x1="16.5017"
                  y1="9.27124"
                  x2="16.5017"
                  y2="24.0923"
                  stroke="#333333"
                  strokeWidth="2.24561"
                  strokeLinecap="round"
                />
                <line
                  x1="9.17749"
                  y1="16.6233"
                  x2="23.9985"
                  y2="16.6233"
                  stroke="#333333"
                  strokeWidth="2.24561"
                  strokeLinecap="round"
                />
              </svg>
            </div>
          </div>
        </section>

        <section className="main-section">
          <div className="round-card reveal-on">
            <div className="round-card-inner reveal-delay round-card-innerBanking">
              <div className="round-card-content">
                <div className="bankingSmallText">EASY CODE INTEGRATION</div>
                <div className="bankingHeading bankingHeading3">
                  Panoramic{" "}
                  <span className="gradient">digital identity management</span>
                </div>
                <div className="bankingDesc">
                  With just a few lines of code, you can secure authentication
                  and identification. We'll handle the compatibility nuances
                  while developers focus on building your products. Gain deeper,
                  accurate insights to make better, risk-based decisions.
                </div>
                <div className="bankingFlex">
                  <a href="/#">
                    Flexible options{" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-arrow-right"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fillRule="evenodd"
                        d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                      />
                    </svg>
                  </a>
                </div>
              </div>
              <div className="round-card-img-container cash">
                <picture className="center-margin">
                  <source
                    srcSet="/images/Pano Digital ID (1).png"
                    type="image/webp"
                  />
                  <source
                    srcSet="/images/Pano Digital ID (1).png"
                    type="image/webp"
                  />
                  <img
                    className="splash-app-feature img-scale"
                    src="/images/Pano Digital ID (1).png"
                    alt=""
                  />
                </picture>
              </div>
            </div>
            <div className="circle-plus reveal-standard">
              <svg
                width="33"
                height="33"
                viewBox="0 0 33 33"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M31.1096 16.3242C31.1096 24.5407 24.4489 31.2014 16.2324 31.2014C8.01597 31.2014 1.35523 24.5407 1.35523 16.3242C1.35523 8.10777 8.01597 1.44703 16.2324 1.44703C24.4489 1.44703 31.1096 8.10777 31.1096 16.3242Z"
                  stroke="#333333"
                  strokeWidth="2.24561"
                />
                <line
                  x1="16.5017"
                  y1="9.27124"
                  x2="16.5017"
                  y2="24.0923"
                  stroke="#333333"
                  strokeWidth="2.24561"
                  strokeLinecap="round"
                />
                <line
                  x1="9.17749"
                  y1="16.6233"
                  x2="23.9985"
                  y2="16.6233"
                  stroke="#333333"
                  strokeWidth="2.24561"
                  strokeLinecap="round"
                />
              </svg>
            </div>
          </div>
        </section>

        {/* <!-- Section: Saving --> */}
        <section className="main-section flex">
          <div className="round-card round-card-yellow yellow square reveal-on card-hover">
            <div className="round-card-inner round-card-inner-yellow saving-m reveal-delay">
              <div className="yellow-content">
                <h2>Execute At Scale</h2>
                <p>
                  AI and ML capabilities eliminate manual processes while
                  driving highly accurate results.
                </p>
              </div>
            </div>
          </div>

          <div className="round-card round-card-yellow yellow square reveal-on card-hover">
            <div className="round-card-inner round-card-inner-yellow saving-m reveal-delay">
              <div className="yellow-content">
                <h2>Instant Verifications</h2>
                <p>
                  Lightning-quick identity verification with instant results
                  taking just a few seconds to complete.
                </p>
              </div>
            </div>
          </div>

          <div className="round-card round-card-yellow yellow square reveal-on card-hover">
            <div className="round-card-inner round-card-inner-yellow saving-m reveal-delay">
              <div className="yellow-content">
                <h2>Omni-Channel Solutions</h2>
                <p>
                  Onboarding across web, mobile, apps, and cross-device and
                  browsers.
                </p>
              </div>
            </div>
          </div>
        </section>

        {/* <!-- Section: Investing --> */}
        <section className="main-section">
          <a
            href="/investing/"
            className="round-card green reveal-on card-hover"
          >
            <div className="round-card-inner reveal-delay">
              <div className="round-card-content">
                <h2>Passwordless Authentication</h2>
                <p>
                  Powerful Web3 authentication, eliminate the need to sign up
                  and ditch the password.
                </p>
              </div>
              <div className="round-card-img-container">
                <picture className="center-margin">
                  <source
                    srcSet="/images/Passwordless Auth.png"
                    type="image/webp"
                  />
                  <source
                    srcSet="/images/Passwordless Auth.png"
                    type="image/webp"
                  />
                  <img
                    className="splash-app-feature short investing-img img-scale"
                    src="/images/Passwordless Auth.png"
                    alt=""
                  />
                </picture>
              </div>
            </div>
            <div className="circle-plus reveal-standard">
              <svg
                width="33"
                height="33"
                viewBox="0 0 33 33"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M31.1096 16.3242C31.1096 24.5407 24.4489 31.2014 16.2324 31.2014C8.01597 31.2014 1.35523 24.5407 1.35523 16.3242C1.35523 8.10777 8.01597 1.44703 16.2324 1.44703C24.4489 1.44703 31.1096 8.10777 31.1096 16.3242Z"
                  stroke="#333333"
                  strokeWidth="2.24561"
                />
                <line
                  x1="16.5017"
                  y1="9.27124"
                  x2="16.5017"
                  y2="24.0923"
                  stroke="#333333"
                  strokeWidth="2.24561"
                  strokeLinecap="round"
                />
                <line
                  x1="9.17749"
                  y1="16.6233"
                  x2="23.9985"
                  y2="16.6233"
                  stroke="#333333"
                  strokeWidth="2.24561"
                  strokeLinecap="round"
                />
              </svg>
            </div>
          </a>
        </section>

        {/* <!-- Section: Budgeting --> */}
        <section className="main-section">
          <a
            href="/budgeting/"
            className="round-card blue reveal-on card-hover"
          >
            <div className="round-card-inner reveal-delay">
              <div className="round-card-content">
                <h2>Global Compliance Protection</h2>
                <p>
                  Intelligent compliance solutions including AML, PEP, OFAC,
                  Global Sanctions, AUSTRAC, OSFI, DFAT & more.
                </p>
              </div>
              <div className="round-card-img-container">
                <picture className="center-margin budget-pic">
                  <source
                    srcSet="/images/Global Compliance Protection (1).png"
                    type="image/webp"
                  />
                  <source
                    srcSet="/images/Global Compliance Protection (1).png"
                    type="image/webp"
                  />
                  <img
                    className="splash-app-feature img-scale"
                    src="/images/Global Compliance Protection (1).png"
                    alt=""
                  />
                </picture>
              </div>
            </div>
            <div className="circle-plus reveal-standard">
              <svg
                width="33"
                height="33"
                viewBox="0 0 33 33"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M31.1096 16.3242C31.1096 24.5407 24.4489 31.2014 16.2324 31.2014C8.01597 31.2014 1.35523 24.5407 1.35523 16.3242C1.35523 8.10777 8.01597 1.44703 16.2324 1.44703C24.4489 1.44703 31.1096 8.10777 31.1096 16.3242Z"
                  stroke="#333333"
                  strokeWidth="2.24561"
                />
                <line
                  x1="16.5017"
                  y1="9.27124"
                  x2="16.5017"
                  y2="24.0923"
                  stroke="#333333"
                  strokeWidth="2.24561"
                  strokeLinecap="round"
                />
                <line
                  x1="9.17749"
                  y1="16.6233"
                  x2="23.9985"
                  y2="16.6233"
                  stroke="#333333"
                  strokeWidth="2.24561"
                  strokeLinecap="round"
                />
              </svg>
            </div>
          </a>
        </section>

        {/* <!-- Section: Serious Security --> */}
        <section className="main-section">
          <div className="round-card black reveal-on">
            <div className="serious-security reveal-delay">
              <div className="serious-security-header">
                <img
                  className="serious-security-icon"
                  src="/images/ic_security_white.svg"
                  alt=""
                />
                <h2>Serious Security</h2>
              </div>
              <div className="serious-security-table flex flex-stack-small flex-start-h">
                <div className="col-3-full-small">
                  <div className="serious-security-item">
                    <h4>Powerful Tools</h4>
                    <p>
                      Advanced security features protecting millions of people
                      across the globe.
                    </p>
                  </div>
                </div>
                <div className="col-3-full-small">
                  <div className="serious-security-item">
                    <h4>Data Security</h4>
                    <p>
                      Designed to ensure enterprise level data security
                      standards.
                    </p>
                  </div>
                </div>
                <div className="col-3-full-small">
                  <div className="serious-security-item">
                    <h4>Security Criteria</h4>
                    <p>
                      Framework aligned to meet AICP criteria, ISO/IEC, GDPR and
                      CCPA standards.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <!-- Section: We've helped members --> */}
        <section className="helped-members reveal-on">
          <div className="splash-center-img reveal-standard">
            <picture>
              <source
                srcSet="/images/img_profileicons.webp"
                type="image/webp"
              />
              <source
                srcSet="/images/img_profileicons.webp"
                type="image/webp"
              />
              <img
                className="large"
                src="/images/img_profileicons.webp"
                alt="Member icons"
              />
            </picture>
            <div className="spacer-tiny" />
            <h2 className="col-1-center">
              Authentic user journeys solidify consumer confidence.
            </h2>
            <div className="spacer-small" />

            <div className="main-button-wrapper" style={{ display: "block" }}>
              <a href="/#" target="_blank" className="button">
                Learn how
              </a>
            </div>
          </div>
        </section>
      </div>

      <div className="sms-modal" style={{ display: modal }}>
        <div onClick={closeModal} className="sms-modal-overlay" />
        <div id="open-modal" className="sms-modal-window">
          <div className="sms-modal-close">
            <svg
              width="15"
              height="15"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.7792 1.28033C12.0721 0.987434 12.0721 0.51256 11.7792 0.219668C11.4863 -0.0732242 11.0115 -0.0732224 10.7186 0.219672L5.99968 4.93859L1.2813 0.220183C0.988409 -0.072711 0.513535 -0.0727128 0.22064 0.220179C-0.0722532 0.513071 -0.0722552 0.987945 0.220636 1.28084L4.93902 5.99926L0.219668 10.7186C-0.0732242 11.0115 -0.0732224 11.4864 0.219672 11.7793C0.512566 12.0722 0.98744 12.0722 1.28033 11.7793L5.99968 7.05992L10.7195 11.7798C11.0124 12.0727 11.4873 12.0727 11.7802 11.7798C12.0731 11.4869 12.0731 11.0121 11.7802 10.7192L7.06033 5.99926L11.7792 1.28033Z"
                fill="#333"
              />
            </svg>
          </div>
          <div className="sms-modal-content-container">
            <div className="sms-modal-content">
              <img
                className="sms-modal-qr"
                src="/images/img_qr_download_app.png"
                alt="Download the app QR code"
              />
              <h1>Scan to download Albert.</h1>
            </div>
            <hr />
          </div>

          <div className="sms-modal-app-buttons">
            <a href="/" target="_blank" rel="noopener">
              <img src="/images/app-store.png" alt="App Store" />
            </a>
            <a href="/" target="_blank" rel="noopener">
              <img src="/images/play-store.png" alt="Play Store" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
