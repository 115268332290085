import React, { useEffect } from "react";

import "./styles.scss";

function Careers() {
  useEffect(() => {
    const script = document.createElement("script");

    script.src = "https://hirevise.blob.core.windows.net/widget/js/app.js";
    script.async = true;

    document.body.appendChild(script);
  }, []);

  return (
    <div className="careers">
      <hv-career-page
        id="guava"
        uid="9c67d924-b59c-47d3-9c29-45c05c9738fa"
        frame="false"
        transparent="false"
        height="600px"
        width="100%"
      />
    </div>
  );
}

export default Careers;
