import React from "react";

import GeneralContactForm from "../Form/GeneralContactForm";

import "./styles.scss";

function CognitiveVideo() {
  return (
    <div className="biometric-verification">
      <div className="banner">
        <div className="banner-inner">
          <h2>Cognitive Video</h2>
          <h1>
            Relational videos,
            <br /> intellictual insight
          </h1>
          <input type="button" value="GET STARTED" />
          <p />
        </div>
      </div>
      <div className="features">
        <h1>Cognitive video enables deep analytical insights</h1>
        <p>
          Understand an individuals personality, motivation, attitude using
          data, facial recognition and mapping to eliminate deep fakes and false
          passes.
        </p>
        <div className="feature-list">
          <div className="feature">
            <img
              src="https://www.circle.com/hubfs/product%20brand/inset-gummie-usdc.png"
              alt="Icon"
            />
            <p>Fight Fraud</p>
          </div>
          <div className="feature">
            <img
              src="https://www.circle.com/hubfs/2tone/email-assets/calendar-bazooka.png"
              alt="Icon"
            />
            <p>Identify Risk</p>
          </div>
          <div className="feature">
            <img src="https://www.circle.com/hubfs/btc.png" alt="Icon" />
            <p>Prevent false positives</p>
          </div>
          <div className="feature">
            <img
              src="https://www.circle.com/hubfs/2tone/business-apple.png"
              alt="Icon"
            />
            <p>Detect Patterns</p>
          </div>
          <div className="feature">
            <img
              src="https://www.circle.com/hubfs/2tone/bank-gumdrop.svg"
              alt="Icon"
            />
            <p>Future Proof Emerging Threats</p>
          </div>
          <div className="feature">
            <img
              src="https://www.circle.com/hubfs/convert-jelly.png"
              alt="Icon"
            />
            <p>Identify Synthetic Identities</p>
          </div>
        </div>
      </div>
      <div className="content">
        <div className="content-text">
          <span>SCALABLE</span>
          <h2>Protect your brand</h2>
        </div>
        <p>
          <a href="/">USDC</a> Digital automated experiences increase customer
          pass-through and enhance your brand.Authentic experiences are are more
          important than ever in a digital-dominant marketplace. Anti-fraud
          measures combined with an unsurpassed experience prove exceptional
          results.
        </p>
        <div className="content-image">
          <img src="/images/Protect your brand.png" /> public\images\Protect
          your brand.png
        </div>
      </div>
      <div className="content content-reverse">
        <div className="content-image">
          <img src="/images/Protect your brand.png" />
        </div>
        <div className="content-text">
          <h2>Designed with trusted partners</h2>
        </div>
        <p>
          Your bitcoin collateral is kept safe with BitGo, a custodian with more
          than 400 institutional clients. We work with lending partners to lend
          USDC to the crypto capital markets.
        </p>
      </div>
      <div className="trust">
        <h2>Hear from our leadership on Trust & Transparency</h2>
        <div className="trust-list">
          <div className="trust-item">
            <img src="https://www.circle.com/hubfs/Blog%20Posts/tnt-blackrock.png" />
            <b>TRUST & TRANSPARENCY</b>
            <p>Deepening our partnership with BlackRock</p>
          </div>
          <div className="trust-item">
            <img src="https://www.circle.com/hubfs/Blog%20Posts/tnt-July-attestation.png" />
            <b>TRUST & TRANSPARENCY</b>
            <p>New Levels of Detail in the Monthly USDC Attestation</p>
          </div>
          <div className="trust-item">
            <img src="https://www.circle.com/hubfs/Blog%20Posts/tnt-July-attestation.png" />
            <b>TRUST & TRANSPARENCY</b>
            <p>Uncharted | The Responsibility of Trust</p>
          </div>
        </div>
        <a href="/">READ MORE</a>
      </div>
      <div className="content content-bottom">
        <div className="content-text">
          <h2>Available in your Circle Account</h2>
          <p>
            Institutional accredited investors can put idle treasury funds to
            work with Circle Yield when you open a free Circle Account, a
            powerful and secure treasury management platform. Seamlessly convert
            dollars into USDC and vice-versa. Pay vendors and receive payment
            from customers in USDC near-instantly 24/7, without the limits of
            traditional banking hours. Opt to automatically renew investments at
            maturity**** to streamline treasury operations.
          </p>
          <a href="/">OPEN A CIRCLE ACCOUNT</a>
        </div>
        <div className="content-image">
          <img src="https://www.circle.com/hubfs/Compressed%20Images/USDC%20-%20compressed.png" />
        </div>
      </div>
      <div className="rates">
        <h2>Circle Yield current rates</h2>
        <p>
          Circle Yield, an{" "}
          <a href="/">institutional investment built on USDC</a>, offers short
          fixed-income terms ranging from from one to twelve months, with
          current interest rates of:
        </p>
        <div className="rate-list">
          <div className="rate">
            <h4>FIXED SHORT TERM**</h4>
            <div className="rate-nested-list">
              <div className="rate-nested">
                <h1>
                  0<sup>%</sup>
                </h1>
                <h3>1 month fixed rate</h3>
              </div>
              <div className="rate-nested">
                <h1>
                  0<sup>%</sup>
                </h1>
                <h3>3 month fixed rate</h3>
              </div>
            </div>
          </div>
          <div className="rate">
            <h4>FIXED LONG TERM**</h4>
            <div className="rate-nested-list">
              <div className="rate-nested">
                <h1>
                  0<sup>%</sup>
                </h1>
                <h3>6 month fixed rate</h3>
              </div>
              <div className="rate-nested">
                <h1>
                  0<sup>%</sup>
                </h1>
                <h3>12 month fixed rate</h3>
              </div>
            </div>
          </div>
        </div>
        <span>
          Rates are purely indicative and are subject to change pending
          availability, approval and market conditions.
        </span>
      </div>
      <GeneralContactForm />
    </div>
  );
}

export default CognitiveVideo;
