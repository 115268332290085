import React from "react";

import "./styles.scss";

function About() {
  return (
    <div className="about">
      <div className="about-header">
        <h1>It all starts with the user journey.</h1>
        <div className="clear spacer-small" />
        <p className="center font-medium-small">
          We're on a mission to deliver a user focused experience that minimizes
          cost and is designed on compliance frameworks. Deliver a seamless,
          automated onboarding experience for individuals, corporate
          organizations and entities while ensuring scalability. Join us in
          redefining identity verification.
        </p>
      </div>
      <div className="content">
        <div className="content-text">
          <h3>Our vision</h3>
          <p>
            There are millions of digital financial scams happening daily across
            the globe. Our vision is simple, play our part in mitigating digital
            fraud by building real-world, practical, security based solutions.
          </p>
          <p>
            ValidEntry is building a world in which no one, regardless of
            status, needs to worry about digital fraud. Our human centric, AI
            focused approach to technology allows verification anytime, anywhere
            in the world, and on any device. We believe in continual learning
            while adapting to an ever-shifting global market resulting in
            enormous amounts of money saved and individual protected.
          </p>
        </div>
        <div className="content-image">
          <img
            className="hero-img-mobile about-side-img spacer-small"
            src="https://albert.com/images/splash/img_neonsign.webp?v=d76a0adf1ba0d13c15d439fe066debfa67aafb8c"
            alt=""
          />
        </div>
      </div>
      <div className="content content-reverse">
        <div className="content-image">
          <img
            className="hero-img about-side-img spacer-small"
            src="https://albert.com/images/splash/img_la_ny.webp?v=d76a0adf1ba0d13c15d439fe066debfa67aafb8c"
            alt=""
          />
        </div>
        <div className="content-text">
          <h3>Powerful technology, simplified</h3>
          <p>
            Trust-first security based software entwined with a true
            consultative approach enables global companies to run powerful
            digital integrations. Our software fights fraud, deploys layers of
            security across applications, reduces platform violations,
            vulnerabilities, and risk. We empower teams with innovative digital
            transformation tools and enhance user engagement.
          </p>
          <p>
            Digital risk mitigation is not easy. The Valid Entry team works with
            each client individually to assess needs and deploy a threat
            stopping armory at the click of a button.
          </p>
        </div>
      </div>
      <section className="content-list">
        <h3>Our values</h3>
        <div className="list">
          <div className="list-item">
            <div className="splash-icon-container large list-image">
              <img
                className="splash-icon bottom"
                src="https://cdn.albert.com/static/img/splash/splash-2021-08/icons/ic_youfirst.svg?v=d76a0adf1ba0d13c15d439fe066debfa67aafb8c"
                alt=""
              />
            </div>
            <b>You, first</b>
            <p>
              Our goal is your success. We want to make each and every
              interaction you have with money better.
            </p>
          </div>
          <div className="list-item">
            <div className="splash-icon-container large list-image">
              <img
                className="splash-icon bottom"
                src="https://cdn.albert.com/static/img/splash/splash-2021-08/icons/ic_humanguidance.svg?v=d76a0adf1ba0d13c15d439fe066debfa67aafb8c"
                alt=""
              />
            </div>
            <b>Human guidance</b>
            <p>
              Everyone has the right to financial expertise. There’s no
              substitute for an open, honest conversation with our Genius money
              experts.
            </p>
          </div>
          <div className="list-item">
            <div className="splash-icon-container large list-image">
              <img
                className="splash-icon bottom"
                src="https://cdn.albert.com/static/img/splash/splash-2021-08/icons/ic_simplicity.svg?v=d76a0adf1ba0d13c15d439fe066debfa67aafb8c"
                alt=""
              />
            </div>
            <b>Simplicity</b>
            <p>
              Anyone can manage their money with Albert. Our products are
              designed to take complexity out of your financial life.
            </p>
          </div>
        </div>
      </section>
      <section className="content-founders">
        <div className="founders-image">
          <img
            className="about-story-img"
            src="https://albert.com/images/splash/img_founders.webp?v=d76a0adf1ba0d13c15d439fe066debfa67aafb8c"
            alt="Co-founders Yinon Ravid and Andrzej Baraniak"
          />
        </div>
        <h3>Meet our founders</h3>
        <div className="founders-text">
          <p>
            Our story starts when co-founders Yinon and Andrzej met as college
            roommates. Andrzej went on to become a consultant to banks. Yinon
            managed money for professional investors, then launched his own
            investing startup.
          </p>
          <p>
            Years later, they reconnected over dinner and reminisced about their
            dorm-room days — a time when their biggest question was whether to
            spend their last $20 on beer or pizza. Here, they decided to create
            a business that would remove the mystery and confusion from
            day-to-day financial decisions. They named it Albert.
          </p>
        </div>
      </section>
      <section className="content-join-us">
        <h4>Join our growing team</h4>
        <p>
          We’re searching for talented, passionate people to join us on this
          extraordinary journey.
        </p>
        <a href="/careers">Join our team</a>
      </section>
    </div>
  );
}

export default About;
