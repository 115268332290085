import React from "react";

import GeneralContactForm from "../Form/GeneralContactForm";

import "./styles.scss";

function Integration() {
  return (
    <div className="biometric-verification">
      <div className="banner">
        <div className="banner-inner">
          <h2>Plug & Play</h2>
          <h1>
            No code,
            <br /> effortless integration
          </h1>
          <input type="button" value="GET STARTED" />
          <p />
        </div>
      </div>
      <div className="features">
        <h1>Powerful Automation</h1>
        <p>
          Secure authentication and identification with just a few lines of
          code. Trust-first authentication, access controls and security layers
          transform and innovate digital applications. Experience deep, accurate
          data points enabling better risk-based decisioning.
        </p>
        <div className="feature-list">
          <div className="feature">
            <img
              src="https://www.circle.com/hubfs/product%20brand/inset-gummie-usdc.png"
              alt="Icon"
            />
            <p>Built on USD Coin (USDC)</p>
          </div>
          <div className="feature">
            <img
              src="https://www.circle.com/hubfs/2tone/email-assets/calendar-bazooka.png"
              alt="Icon"
            />
            <p>Fixed rates and flexible terms</p>
          </div>
          <div className="feature">
            <img src="https://www.circle.com/hubfs/btc.png" alt="Icon" />
            <p>Overcollateralized with bitcoin</p>
          </div>
          <div className="feature">
            <img
              src="https://www.circle.com/hubfs/2tone/business-apple.png"
              alt="Icon"
            />
            <p>Institutional-grade</p>
          </div>
          <div className="feature">
            <img
              src="https://www.circle.com/hubfs/2tone/bank-gumdrop.svg"
              alt="Icon"
            />
            <p>Clear regulatory framework</p>
          </div>
          <div className="feature">
            <img
              src="https://www.circle.com/hubfs/convert-jelly.png"
              alt="Icon"
            />
            <p>Opt to automatically renew investments at maturity****</p>
          </div>
        </div>
      </div>
      <div className="content">
        <div className="content-text">
          <span>BUILT ON USDC</span>
          <h2>Transparency & compliance</h2>
        </div>
        <p>
          <a href="/#">USDC</a> is a dollar digital currency that’s always
          redeemable 1:1 for U.S. dollars. It moves globally at internet speed,
          enabling people and businesses to send funds faster and with less cost
          than traditional payment methods.
        </p>
        <div className="content-image">
          <img src="https://www.circle.com/hubfs/Compressed%20Images/USDC%20-%20compressed.png" />
        </div>
      </div>
      <div className="content content-reverse">
        <div className="content-image">
          <img src="https://www.circle.com/hubfs/Business%20(1).png" />
        </div>
        <div className="content-text">
          <h2>Designed with trusted partners</h2>
        </div>
        <p>
          Your bitcoin collateral is kept safe with BitGo, a custodian with more
          than 400 institutional clients. We work with lending partners to lend
          USDC to the crypto capital markets.
        </p>
      </div>
      <div className="trust">
        <h2>Hear from our leadership on Trust & Transparency</h2>
        <div className="trust-list">
          <div className="trust-item">
            <img src="https://www.circle.com/hubfs/Blog%20Posts/tnt-blackrock.png" />
            <b>TRUST & TRANSPARENCY</b>
            <p>Deepening our partnership with BlackRock</p>
          </div>
          <div className="trust-item">
            <img src="https://www.circle.com/hubfs/Blog%20Posts/tnt-July-attestation.png" />
            <b>TRUST & TRANSPARENCY</b>
            <p>New Levels of Detail in the Monthly USDC Attestation</p>
          </div>
          <div className="trust-item">
            <img src="https://www.circle.com/hubfs/Blog%20Posts/tnt-July-attestation.png" />
            <b>TRUST & TRANSPARENCY</b>
            <p>Uncharted | The Responsibility of Trust</p>
          </div>
        </div>
        <a href="/#">READ MORE</a>
      </div>
      <div className="content content-bottom">
        <div className="content-text">
          <h2>Available in your Circle Account</h2>
          <p>
            Institutional accredited investors can put idle treasury funds to
            work with Circle Yield when you open a free Circle Account, a
            powerful and secure treasury management platform. Seamlessly convert
            dollars into USDC and vice-versa. Pay vendors and receive payment
            from customers in USDC near-instantly 24/7, without the limits of
            traditional banking hours. Opt to automatically renew investments at
            maturity**** to streamline treasury operations.
          </p>
          <a href="/#">OPEN A CIRCLE ACCOUNT</a>
        </div>
        <div className="content-image">
          <img src="https://www.circle.com/hubfs/Compressed%20Images/USDC%20-%20compressed.png" />
        </div>
      </div>
      <div className="rates">
        <h2>Circle Yield current rates</h2>
        <p>
          Circle Yield, an{" "}
          <a href="/#">institutional investment built on USDC</a>, offers short
          fixed-income terms ranging from from one to twelve months, with
          current interest rates of:
        </p>
        <div className="rate-list">
          <div className="rate">
            <h4>FIXED SHORT TERM**</h4>
            <div className="rate-nested-list">
              <div className="rate-nested">
                <h1>
                  0<sup>%</sup>
                </h1>
                <h3>1 month fixed rate</h3>
              </div>
              <div className="rate-nested">
                <h1>
                  0<sup>%</sup>
                </h1>
                <h3>3 month fixed rate</h3>
              </div>
            </div>
          </div>
          <div className="rate">
            <h4>FIXED LONG TERM**</h4>
            <div className="rate-nested-list">
              <div className="rate-nested">
                <h1>
                  0<sup>%</sup>
                </h1>
                <h3>6 month fixed rate</h3>
              </div>
              <div className="rate-nested">
                <h1>
                  0<sup>%</sup>
                </h1>
                <h3>12 month fixed rate</h3>
              </div>
            </div>
          </div>
        </div>
        <span>
          Rates are purely indicative and are subject to change pending
          availability, approval and market conditions.
        </span>
      </div>
      <div className="signup">
        <h1>
          Start with a free <span>Circle Account</span>
        </h1>
        <p>
          Access USDC for payments, trading and DeFi. For institutional
          accredited investors, earn yield on idle USDC.
        </p>
        <div className="signup-actions">
          <a href="/#">GET STARTED</a>
          <a href="/#">CONTACT SALES</a>
        </div>
      </div>
      <GeneralContactForm />
    </div>
  );
}

export default Integration;
