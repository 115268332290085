import React from "react";

function Footer() {
  return (
    <footer style={{ position: "relative" }}>
      <div className="footer-content">
        <div className="content-full text-left">
          <a href="/">
            <img className="footer-logo" src="/images/logo.png" alt="logo" />
          </a>
        </div>
        <div className="clear spacer-small" />

        <div className="col-4">
          <span className="bold">Company</span>
          <br />
          <a href="/about/" rel="noopener">
            About
          </a>
          <br />
          <a href="/careers/" rel="noopener">
            Careers
          </a>
        </div>

        <div className="col-4">
          <span className="bold">Support</span>
          <br />
        </div>

        <div>
          <a
            href="mailto:support@validentry.com"
            target="_blank"
            rel="noreferrer"
          >
            support@validentry.com
          </a>
        </div>
        <div className="col-4">
          <span className="bold">Legal</span>
          <br />
          <a href="/terms/" target="_blank">
            Terms of Use
          </a>
          <br />
          <a href="/privacy/" target="_blank">
            Privacy Policy
          </a>
        </div>
        <div className="col-4 col-last">
          <span className="bold">Coming Soon</span>
          <br />
          <div className="app-buttons">
            <a href="/" target="_blank" rel="noopener">
              <img src="/images/app-store.png" alt="App Store" />
            </a>
            <a href="/" target="_blank" rel="noopener">
              <img src="/images/play-store.png" alt="Play Store" />
            </a>
          </div>
        </div>

        <div
          style={{
            position: "absolute",
            bottom: "30px",
            left: "0px",
            marginRight: "auto",
            textAlign: "center",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <span
            style={{
              textAlign: "center",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            2023 AnonyDoxx, LLC All Rights Reserved.
          </span>{" "}
        </div>

        <div className="clear spacer-medium-large" />
      </div>
    </footer>
  );
}

export default Footer;
