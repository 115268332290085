import React from "react";

import GeneralContactForm from "../Form/GeneralContactForm";

import "./styles.scss";

function BackgroundCheck() {
  return (
    <div className="biometric-verification">
      <div className="banner">
        <div className="banner-inner">
          <h2>Credibility</h2>
          <h1> Background Checks</h1>
          <h1>240+ Countries</h1>

          <input type="button" value="GET STARTED" />
          {/* <p>Imagine the capabilities</p> */}
        </div>
      </div>

      <div className="content">
        <div className="content-text">
          {/* <span>BUILT ON USDC</span> */}
          <h2> Global screening resources</h2>
        </div>
        <p>
          Perform background checks in 240+ countries at the click of a button
          and take your onboarding to a new level. Validate and meet compliance
          effortlessly with end-to-end workflows, cognitive intelligence and
          protect business assets.
        </p>
        <div className="content-image">
          <img src="/images/albert-logo.svg" />
        </div>
      </div>
      <section className="content content-list">
        <div>
          {" "}
          <h3>Flexible. Confident. Smart.</h3>
          <h5>
            Consumer driven data enables confidence in authentication across
            countries.
          </h5>
        </div>

        <div className="list">
          <div className="list-item">
            <div className="splash-icon-container large list-image">
              <img
                className="splash-icon bottom"
                src="https://cdn.albert.com/static/img/splash/splash-2021-08/icons/ic_cashbonuses.svg?v=d76a0adf1ba0d13c15d439fe066debfa67aafb8c"
                alt=""
              />
            </div>
            <b>Validate accuracy</b>
            <p>
              Comprehensive, digital validation increases information quality
              and eliminates fraud.
            </p>
          </div>
          <div className="list-item">
            <div className="splash-icon-container large list-image">
              <img
                className="splash-icon bottom"
                src="https://cdn.albert.com/static/img/splash/splash-2021-08/icons/ic_cashbonuses.svg?v=d76a0adf1ba0d13c15d439fe066debfa67aafb8c"
                alt=""
              />
            </div>
            <b>Safety & security</b>
            <p>
              Mitigate internal workplace threats with screening for critical
              consumer insights allowing for protection to your business,
              applications and employees.
            </p>
          </div>
          <div className="list-item">
            <div className="splash-icon-container large list-image">
              <img
                className="splash-icon bottom"
                src="https://cdn.albert.com/static/img/splash/splash-2021-08/icons/ic_unlimitedtransfers.svg?v=d76a0adf1ba0d13c15d439fe066debfa67aafb8c"
                alt=""
              />
            </div>
            <b>Regulatory compliance</b>
            <p>
              Our background screening comes with regulatory compliance
              expertise. Capitalize on a screening solution that satisfies
              industry standards including state and federal laws abnd mitigate
              risk and legal issues.
            </p>
          </div>
          <div className="list-item">
            <div className="splash-icon-container large list-image">
              <img
                className="splash-icon bottom"
                src="https://cdn.albert.com/static/img/splash/splash-2021-08/icons/ic_unlimitedtransfers.svg?v=d76a0adf1ba0d13c15d439fe066debfa67aafb8c"
                alt=""
              />
            </div>
          </div>
        </div>
      </section>
      <div className="content content-reverse">
        <div className="content-image">
          <img src="https://www.circle.com/hubfs/Business%20(1).png" />
        </div>
        <div className="content-text">
          <h2 style={{ textAlign: "center" }}>Focus on the journey</h2>
        </div>
        <p>
          Increase pass-through, protect your brand and enhance the customer
          experience. In a digitally-dominant marketplace ensure an authentic
          experience with exceptional results.
        </p>
      </div>
      <div className="trust">
        <h2>Truth in authentication at lightening-speed</h2>
        <div className="trust-list">
          <div className="trust-item">
            <div className="flex-grow-1">
              <div>
                <b>GLOBAL COMPLIANCE</b>
                <p>
                  Easily verify & identify consumers regardless of the country
                  they reside in
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                className="justify-lg-start"
              >
                <img src="https://www.circle.com/hubfs/Compressed%20Images/USDC%20-%20compressed.png" />
              </div>
            </div>
            <div>
              {" "}
              <img src="/images/logo.png" />
            </div>

            <b>TRUST & TRANSPARENCY</b>
            <br />
            <span>Leverage our global compliance solutions</span>
          </div>
          <div className="trust-item">
            <div className="flex-grow-1">
              <div>
                <b>FIGHT FRAUD</b>
                <p>
                  Identify synthetic impersonation, target discrepancies and
                  pinpoint truth in verification.
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                className="justify-lg-start"
              >
                <img src="https://www.circle.com/hubfs/Compressed%20Images/USDC%20-%20compressed.png" />
              </div>
            </div>
            <div>
              {" "}
              <img src="/images/logo.png" />
            </div>

            <b>DATA & PROTECTION</b>
            <br />
            <span>Powerful fraud detection tools protect your business</span>
          </div>

          <div className="trust-item">
            <div className="flex-grow-1">
              <div>
                <b>Prevent loss</b>
                <p>Stop organized fraud rings by going beyond standard KYC</p>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                className="justify-lg-start"
              >
                <img src="https://www.circle.com/hubfs/Compressed%20Images/USDC%20-%20compressed.png" />
              </div>
            </div>
            <div>
              {" "}
              <img src="/images/logo.png" />
            </div>

            <b>TRENDS & VALIDATION</b>
            <br />
            <span>Deploy layers of security against bad actors</span>
          </div>
        </div>
        <a href="/">READ MORE</a>
      </div>
      <div className="content content-bottom">
        <div className="content-text">
          <h2>Increase operational efficiency</h2>
          <p>
            Automate your workflows, reduce costs, implement cognitive identity
            solutions without the need to code. Streamline an end-to-end process
            resulting in immediate results.
          </p>
          <a href="/">Find out how</a>
        </div>
        <div className="content-image">
          <img src="https://www.circle.com/hubfs/Compressed%20Images/USDC%20-%20compressed.png" />
        </div>
      </div>
      <div className="rates">
        <h2>Circle Yield current rates</h2>
        <p>
          Circle Yield, an{" "}
          <a href="/">institutional investment built on USDC</a>, offers short
          fixed-income terms ranging from from one to twelve months, with
          current interest rates of:
        </p>
        <div className="rate-list">
          <div className="rate">
            <h4>FIXED SHORT TERM**</h4>
            <div className="rate-nested-list">
              <div className="rate-nested">
                <h1>
                  0<sup>%</sup>
                </h1>
                <h3>1 month fixed rate</h3>
              </div>
              <div className="rate-nested">
                <h1>
                  0<sup>%</sup>
                </h1>
                <h3>3 month fixed rate</h3>
              </div>
            </div>
          </div>
          <div className="rate">
            <h4>FIXED LONG TERM**</h4>
            <div className="rate-nested-list">
              <div className="rate-nested">
                <h1>
                  0<sup>%</sup>
                </h1>
                <h3>6 month fixed rate</h3>
              </div>
              <div className="rate-nested">
                <h1>
                  0<sup>%</sup>
                </h1>
                <h3>12 month fixed rate</h3>
              </div>
            </div>
          </div>
        </div>
        <span>
          Rates are purely indicative and are subject to change pending
          availability, approval and market conditions.
        </span>
      </div>
      <div className="signup">
        <h1>
          Start protecting <span>your business</span>
        </h1>
        <p>
          Access to fast, effortless scaling that moves with your business
          without risking quality. Know you’re in compliance without the
          customer drop-off.
        </p>
        <div className="signup-actions">
          <a href="/">GET STARTED</a>
          <a href="/">CONTACT SALES</a>
        </div>
      </div>
      <GeneralContactForm />
    </div>
  );
}

export default BackgroundCheck;
