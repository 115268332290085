/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from "react";

function Header() {
  const [backdrop, setbackdrop] = useState("");
  const [navbar, setnavbar] = useState("");
  const [dropdownDesktop, setdropdownDesktop] = useState("");
  const [mobileDrop, setmobileDrop] = useState("");
  const [dropdownMobile, setdropdownMobile] = useState("");
  const [hidden, sethidden] = useState("hidden");

  const handleScroll = () => {
    if (window.pageYOffset < 20) {
      setnavbar("");
      setdropdownMobile("");
    }

    if (window.pageYOffset > 20) {
      setnavbar("shrink");
      setdropdownMobile("topSpace");
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
  }, []);

  return (
    <header>
      <nav className={`navbar ${navbar}`}>
        <div className="col-nav">
          <a href="/">
            <img
              id="logo"
              className={`logo ${navbar}`}
              src="/images/logo.png"
              alt="logo"
            />
          </a>
        </div>
        <div className="col-nav right text-right">
          {/* <!-- Desktop Navigation --> */}
          <div id="desktop-nav">
            <button
              type="button"
              onClick={() => {
                setbackdrop("showBackdrop");
                setdropdownDesktop("showSideBar");
              }}
              id="dropdown-button-desktop"
            >
              <svg
                width="41"
                height="30"
                viewBox="0 0 41 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M7.52734 8.33138C7.52734 7.595 8.1243 6.99805 8.86068 6.99805H32.8607C33.5971 6.99805 34.194 7.595 34.194 8.33138C34.194 9.06776 33.5971 9.66471 32.8607 9.66471H8.86068C8.1243 9.66471 7.52734 9.06776 7.52734 8.33138Z"
                  fill="#333333"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M7.52734 14.998C7.52734 14.2617 8.1243 13.6647 8.86068 13.6647H32.8607C33.5971 13.6647 34.194 14.2617 34.194 14.998C34.194 15.7344 33.5971 16.3314 32.8607 16.3314H8.86068C8.1243 16.3314 7.52734 15.7344 7.52734 14.998Z"
                  fill="#333333"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M7.52734 21.6647C7.52734 20.9283 8.1243 20.3314 8.86068 20.3314H32.8607C33.5971 20.3314 34.194 20.9283 34.194 21.6647C34.194 22.4011 33.5971 22.998 32.8607 22.998H8.86068C8.1243 22.998 7.52734 22.4011 7.52734 21.6647Z"
                  fill="#333333"
                />
              </svg>
            </button>
            <div
              id="desktopDrop"
              className={`backdrop ${backdrop}`}
              role="presentation"
              onClick={() => {
                setbackdrop("");
                setdropdownDesktop("");
              }}
            />
            <div
              id="dropdown-desktop"
              className={`dropdown-desktop exit ${dropdownDesktop}`}
            >
              <button
                type="button"
                onClick={() => {
                  setbackdrop("");
                  setdropdownDesktop("");
                }}
                className="close-icon"
              >
                <svg
                  width="41"
                  height="30"
                  viewBox="0 0 41 30"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.6075 22.5408C10.0868 23.0615 10.0868 23.9057 10.6075 24.4264C11.1282 24.9471 11.9724 24.9471 12.4931 24.4264L20.0355 16.8841L27.5779 24.4265C28.0986 24.9471 28.9428 24.9471 29.4635 24.4265C29.9842 23.9058 29.9842 23.0615 29.4635 22.5408L21.9211 14.9984L29.4637 7.45583C29.9844 6.93513 29.9844 6.09091 29.4637 5.57021C28.943 5.04951 28.0988 5.04951 27.5781 5.57021L20.0355 13.1128L12.4929 5.57027C11.9722 5.04957 11.128 5.04957 10.6073 5.57027C10.0866 6.09097 10.0866 6.93519 10.6073 7.45589L18.1499 14.9984L10.6075 22.5408Z"
                    fill="#333333"
                  />
                </svg>
              </button>
              <div className="sections">
                <div className="features-section-desktop">
                  <button
                    type="button"
                    onClick={() =>
                      hidden === "hidden" ? sethidden("") : sethidden("hidden")
                    }
                  >
                    Technology{" "}
                    {hidden === "hidden" ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="30"
                        height="30"
                        fill="currentColor"
                        className="bi bi-arrow-down-short"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fillRule="evenodd"
                          d="M8 4a.5.5 0 0 1 .5.5v5.793l2.146-2.147a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L7.5 10.293V4.5A.5.5 0 0 1 8 4z"
                        />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="30"
                        height="30"
                        fill="currentColor"
                        className="bi bi-arrow-up-short"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fillRule="evenodd"
                          d="M8 12a.5.5 0 0 0 .5-.5V5.707l2.146 2.147a.5.5 0 0 0 .708-.708l-3-3a.5.5 0 0 0-.708 0l-3 3a.5.5 0 1 0 .708.708L7.5 5.707V11.5a.5.5 0 0 0 .5.5z"
                        />
                      </svg>
                    )}
                  </button>
                  <a href="/biometric-verification" className={hidden}>
                    Biometric Verification
                  </a>
                  <a href="/location-identification" className={hidden}>
                    Location Identification
                  </a>
                  <a href="/background-check" className={hidden}>
                    Background Checks
                  </a>
                  <a href="/cognitive-video" className={hidden}>
                    Cognitive Video
                  </a>
                  <a href="/security">Security</a>
                  <a href="/integration">Integration</a>
                  <a href="/about">About</a>
                </div>
                <div className="company-info-section-desktop">
                  <a href="/about">About</a>
                  <a href="/careers/">Careers</a>
                </div>
                <div className="app-links-section-desktop">
                  <a href="/" target="_blank" rel="noopener">
                    <img src="/images/app-store.png" alt="App Store" />
                  </a>
                  <a href="/" target="_blank" rel="noopener">
                    <img src="/images/play-store.png" alt="Play Store" />
                  </a>
                </div>
              </div>
            </div>
          </div>

          {/* <!-- Mobile Navigation --> */}
          <div id="mobile-nav">
            <button
              type="button"
              onClick={() => {
                mobileDrop === "" ? setmobileDrop("show") : setmobileDrop("");
              }}
              id="mobile-hamburger-icon"
            >
              <svg
                width="41"
                height="30"
                viewBox="0 0 41 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M7.52734 8.33138C7.52734 7.595 8.1243 6.99805 8.86068 6.99805H32.8607C33.5971 6.99805 34.194 7.595 34.194 8.33138C34.194 9.06776 33.5971 9.66471 32.8607 9.66471H8.86068C8.1243 9.66471 7.52734 9.06776 7.52734 8.33138Z"
                  fill="#333333"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M7.52734 14.998C7.52734 14.2617 8.1243 13.6647 8.86068 13.6647H32.8607C33.5971 13.6647 34.194 14.2617 34.194 14.998C34.194 15.7344 33.5971 16.3314 32.8607 16.3314H8.86068C8.1243 16.3314 7.52734 15.7344 7.52734 14.998Z"
                  fill="#333333"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M7.52734 21.6647C7.52734 20.9283 8.1243 20.3314 8.86068 20.3314H32.8607C33.5971 20.3314 34.194 20.9283 34.194 21.6647C34.194 22.4011 33.5971 22.998 32.8607 22.998H8.86068C8.1243 22.998 7.52734 22.4011 7.52734 21.6647Z"
                  fill="#333333"
                />
              </svg>
            </button>
            <button
              type="button"
              id="mobile-close-icon"
              className="close-button hide"
            >
              <svg
                width="41"
                height="30"
                viewBox="0 0 41 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.6075 22.5408C10.0868 23.0615 10.0868 23.9057 10.6075 24.4264C11.1282 24.9471 11.9724 24.9471 12.4931 24.4264L20.0355 16.8841L27.5779 24.4265C28.0986 24.9471 28.9428 24.9471 29.4635 24.4265C29.9842 23.9058 29.9842 23.0615 29.4635 22.5408L21.9211 14.9984L29.4637 7.45583C29.9844 6.93513 29.9844 6.09091 29.4637 5.57021C28.943 5.04951 28.0988 5.04951 27.5781 5.57021L20.0355 13.1128L12.4929 5.57027C11.9722 5.04957 11.128 5.04957 10.6073 5.57027C10.0866 6.09097 10.0866 6.93519 10.6073 7.45589L18.1499 14.9984L10.6075 22.5408Z"
                  fill="#333333"
                />
              </svg>
            </button>
            <div
              id="mobileDrop"
              className={`dropdown-mobile ${dropdownMobile} ${mobileDrop}`}
            >
              <div id="features-mobile" className="features-section-mobile">
                <button
                  type="button"
                  onClick={() =>
                    hidden === "hidden" ? sethidden("") : sethidden("hidden")
                  }
                >
                  Technology{" "}
                  {hidden === "hidden" ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="30"
                      height="30"
                      fill="currentColor"
                      className="bi bi-arrow-down-short"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fillRule="evenodd"
                        d="M8 4a.5.5 0 0 1 .5.5v5.793l2.146-2.147a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L7.5 10.293V4.5A.5.5 0 0 1 8 4z"
                      />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="30"
                      height="30"
                      fill="currentColor"
                      className="bi bi-arrow-up-short"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fillRule="evenodd"
                        d="M8 12a.5.5 0 0 0 .5-.5V5.707l2.146 2.147a.5.5 0 0 0 .708-.708l-3-3a.5.5 0 0 0-.708 0l-3 3a.5.5 0 1 0 .708.708L7.5 5.707V11.5a.5.5 0 0 0 .5.5z"
                      />
                    </svg>
                  )}
                </button>
                <a href="/biometric-verification" className={hidden}>
                  Biometric Verification
                </a>
                <a href="/location-identification" className={hidden}>
                  Location Identification
                </a>
                <a href="/background-check" className={hidden}>
                  Background Checks
                </a>
                <a href="/cognitive-video" className={hidden}>
                  Cognitive Video
                </a>
                <a href="/security">Security</a>
                <a href="/integration">Integration</a>
                <a href="/about">About</a>
              </div>
              <div className="company-info-section-mobile">
                <a href="/about">About</a>
                <a href="/careers/">Careers</a>
              </div>

              <a
                href="https://app.albrt.co/download"
                className="get-app-btn text-center"
              >
                Get Started
              </a>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
}

export default Header;
