import React from "react";

function GeneralContactForm() {
  return (
    <form className="form">
      <h1>Talk to our team</h1>
      <p>We like meeting new people. Tell us how we can help.</p>
      <div className="input">
        <label htmlFor="firstName">
          FIRST NAME*
          <input name="firstName" type="text" />
        </label>
      </div>
      <div className="input">
        <label htmlFor="lastName">
          Last NAME*
          <input name="lastName" type="text" />
        </label>
      </div>
      <div className="input">
        <label htmlFor="workEmail">
          WORK EMAIL ADDRESS*
          <input type="email" name="workEmail" />
        </label>
      </div>
      <div className="input">
        <label htmlFor="country">
          COUNTRY*
          <select name="country">
            <option>United States of America</option>
          </select>
        </label>
      </div>
      <span>
        By submitting this form, you agree to receive marketing and other
        communications from Circle about the Circle Products and other company
        updates. You can unsubscribe from these communications at any time. For
        more information on our privacy practices, please review our{" "}
        <a href="/privacy">Privacy Policy</a>.
      </span>
      <input id="formInput" type="submit" value="Submit" />
    </form>
  );
}

export default GeneralContactForm;
